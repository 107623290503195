import * as React from 'react';
import { graphql, PageRendererProps } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/Layout';
import { BaseContainer } from '../components/baseComponents';
import { colors, mq } from '../styles/theme';
import { NotFoundPageQuery } from '../../gatsby-graphql';

interface INotFoundPage extends PageRendererProps {
  data: NotFoundPageQuery;
  pageContext: {
    locale: string;
  };
}

const NotFoundPage: React.FC<INotFoundPage> = ({
  data: { siteNavigation, title, body, footerSocialLinkLabel },
  pageContext: { locale },
  location: { pathname },
}) => (
  <Layout
    pagePathName={
      pathname !== '/en/404' && pathname !== '/es/404' ? '/en/404' : pathname
    }
    pageTitle="404"
    lang={locale}
    siteNavigation={siteNavigation}
    footerSocialLabel={footerSocialLinkLabel?.value as string}
  >
    <StyledBaseContainer>
      <h1>{title?.value}</h1>
      <p>{body?.value}</p>
    </StyledBaseContainer>
  </Layout>
);

const StyledBaseContainer = styled(BaseContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 5rem;
  ${mq.desktop} {
    padding-top: 10rem;
  }
  h1 {
    font-size: 10rem;
    background-color: ${colors.surface};
    ${mq.tablet} {
      font-size: 15rem;
    }
    ${mq.desktop} {
      font-size: 20rem;
    }
  }
`;

export default NotFoundPage;

export const pageQuery = graphql`
  query NotFoundPage($locale: String!) {
    ...PageInfoFragment
    title: contentfulLabel(
      slug: { eq: "404-title" }
      node_locale: { eq: $locale }
    ) {
      value
    }
    body: contentfulLabel(
      slug: { eq: "404-body" }
      node_locale: { eq: $locale }
    ) {
      value
    }
  }
`;
